import { WorkOrderType } from 'common/types/WorkOrder';
import { keyHandler } from 'styleguide/src/utils/accessibilityUtils';
import { KeyboardEvent } from 'react';

import {
  WorkOrdersHeaderStyled,
  WorkOrdersHeaderRecentUpdates,
  WorkOrdersHeaderButtons,
  WorkOrdersHeaderButton,
  WorkOrdersHeaderDivider,
} from './WorkOrdersHeader.styles';

interface WorkOrdersHeaderProps {
  onTableChange?: (tableType: WorkOrderType) => void;
  tab: WorkOrderType;
}

const WorkOrdersHeader = ({
  onTableChange,
  tab = WorkOrderType.JOB,
}: WorkOrdersHeaderProps) => {
  return (
    <WorkOrdersHeaderStyled>
      <WorkOrdersHeaderRecentUpdates>
        Recent Updates
      </WorkOrdersHeaderRecentUpdates>
      <WorkOrdersHeaderDivider />
      <WorkOrdersHeaderButtons>
        <WorkOrdersHeaderButton
          selected={tab === WorkOrderType.JOB}
          onClick={() => onTableChange && onTableChange(WorkOrderType.JOB)}
          tabIndex={0}
          onKeyDown={(e: KeyboardEvent) => {
            keyHandler({
              keyEvent: e,
              eventHandler: () =>
                onTableChange && onTableChange(WorkOrderType.JOB),
            });
          }}
        >
          Jobs
        </WorkOrdersHeaderButton>
        <WorkOrdersHeaderButton
          selected={tab === WorkOrderType.PROJECT}
          onClick={() => onTableChange && onTableChange(WorkOrderType.PROJECT)}
          tabIndex={0}
          onKeyDown={(e: KeyboardEvent) => {
            keyHandler({
              keyEvent: e,
              eventHandler: () =>
                onTableChange && onTableChange(WorkOrderType.PROJECT),
            });
          }}
        >
          Projects
        </WorkOrdersHeaderButton>
      </WorkOrdersHeaderButtons>
    </WorkOrdersHeaderStyled>
  );
};

export default WorkOrdersHeader;
