import styled from 'styled-components';
import CaretLeftIcon from '../icons/CaretLeftIcon';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:not(:disabled) {
    color: ${(props) => props.theme.colors.black};
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 66px;
`;

export const PaginationItem = styled.div<{ selected?: boolean }>`
  justify-content: center;
  align-items: center;
  height: 30px;
  display: flex;
  font-size: 12px;
  border-radius: 10px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily.body};
  color: ${({ theme }) => theme.colors.black};
  border: ${({ selected, theme }) =>
    selected ? `solid 1px ${theme.colors.black}` : 'none'};
  padding: ${({ selected }) => (selected ? '0 10px;' : 'none')};
  min-width: ${({ selected }) => (selected ? '30px;' : 'none')};
`;

export const CaretRightIcon = styled(CaretLeftIcon)`
  transform: scaleX(-1);
`;
