import styled from 'styled-components';
import { CountCellActions, CountCellBody } from './CountCell/CountCell.styles';
import {
  StatusCellDetailsButton,
  StatusCellEditButton,
  StatusCellTitleAndIndicator,
} from './StatusCell/StatusCell.styles';
import BasePagination from 'styleguide/src/components/Pagination';

export const Pagination = styled(BasePagination)`
  margin-top: 10px;
`;

export const TableWrapper = styled.div`
  tr {
    &:focus-visible,
    &:hover {
      ${StatusCellTitleAndIndicator} {
        display: none;
      }
      ${CountCellBody} {
        display: none;
      }
      ${StatusCellDetailsButton},
      ${StatusCellEditButton} {
        opacity: 1;
        position: static;
      }
      ${CountCellActions} {
        display: flex;
      }
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
`;
