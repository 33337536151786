import type { WorkOrder } from 'common/types/WorkOrder';
import {
  formatDateString,
  formatStartEndHoursString,
} from 'utils/helpers/formatDateTimes';
import { DateContainer, HoursContainer } from './ScheduledShiftCell.styles';

function ScheduledShiftCell({ market, acceptedTimeSlot }: WorkOrder) {
  if (!acceptedTimeSlot) {
    return <DateContainer>unclaimed</DateContainer>;
  }
  const { timeZone } = market;
  return (
    <div>
      <DateContainer>
        {formatDateString(acceptedTimeSlot.startDate, timeZone)}
      </DateContainer>
      <HoursContainer>
        {formatStartEndHoursString(
          acceptedTimeSlot.startDate,
          acceptedTimeSlot.endDate,
          timeZone,
        )}
      </HoursContainer>
    </div>
  );
}

export default ScheduledShiftCell;
