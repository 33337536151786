import styled from 'styled-components';
import {
  BodySmall,
  Heading6,
} from 'styleguide/src/components/Typography/Typography.styles';

interface WorkOrdersHeaderButtonProps {
  selected?: boolean;
}

export const WorkOrdersHeaderStyled = styled.div``;

export const WorkOrdersHeaderRecentUpdates = styled.div`
  ${BodySmall};
  color: ${(props) => props.theme.colors.black};
`;

export const WorkOrdersHeaderButtons = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  margin: 16px 0;
`;

export const WorkOrdersHeaderDivider = styled.div`
  background-color: #eeeeee;
  height: 1px;
  width: 100%;
  margin-top: 8px;
`;

export const WorkOrdersHeaderButton = styled.div<WorkOrdersHeaderButtonProps>`
  background: ${(props) =>
    props.selected ? props.theme.colors.wrenoYellowPrimary : 'transparent'};
  color: ${(props) => (props.selected ? props.theme.colors.black : '#717971')};
  ${Heading6};
  border-radius: 50px;
  padding: 0 15px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
`;
