import { WorkOrderStatus } from 'common/types/WorkOrderStatus';
import StatusChip from './StatusChip';
import {
  Container,
  ClearButton,
  StatusChipLists,
  StatusChipList,
  StatusChipLabel,
} from './index.styles';
import { WorkOrderCompletionStatus } from '../WorkOrdersTable/WorkOrdersTable';

type Props = {
  filters: WorkOrderStatus[];
  filterLists: WorkOrderCompletionStatus;
  onSelect: (filters: WorkOrderStatus[]) => void;
};

const FilterByStatus = ({ filters, filterLists, onSelect }: Props) => {
  const isFiltersSelected = filters.length !== filterLists.length;
  const onStatusChipClick = (status: WorkOrderStatus) => {
    if (!isFiltersSelected) {
      onSelect([status]);
    } else {
      if (filters.includes(status)) {
        onSelect(filters.filter((f) => f !== status));
      } else {
        onSelect([...filters, status]);
      }
    }
  };

  const onClear = () => {
    onSelect(filterLists);
  };

  return (
    <Container>
      <StatusChipLabel variant="heading6">Filter By Status:</StatusChipLabel>
      <StatusChipLists>
        {filterLists.map((status: WorkOrderStatus) => (
          <StatusChipList key={status}>
            <StatusChip
              active={!isFiltersSelected ? false : filters.includes(status)}
              status={status}
              onClick={onStatusChipClick}
            />
          </StatusChipList>
        ))}
        {isFiltersSelected && (
          <StatusChipList>
            <ClearButton variant="tertiary" onClick={onClear}>
              Clear
            </ClearButton>
          </StatusChipList>
        )}
      </StatusChipLists>
    </Container>
  );
};

export default FilterByStatus;
