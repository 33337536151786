import Loader from '../Loader';
import {
  Button,
  Container,
  PaginationContainer,
  PaginationItem,
  CaretRightIcon,
} from './Pagination.styles';
import CaretLeftIcon from '../icons/CaretLeftIcon';
import Typography from '../Typography';

export type PaginationProps = {
  totalCount: {
    value: number;
    isLoading: boolean;
  };
  currentPage: number;
  pageSize: number;
  className?: string;
  onPageChange: (page: number) => void;
};

const Pagination = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
  className,
}: PaginationProps) => {
  if (currentPage === 0) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = Math.ceil(totalCount.value / pageSize) || 1;

  return (
    <Container className={`${className} Wreno_Pagination--container`}>
      <Button type="button" disabled={currentPage === 1} onClick={onPrevious}>
        <CaretLeftIcon />
      </Button>
      <PaginationContainer>
        <PaginationItem selected>{currentPage}</PaginationItem>
        <Typography variant="bodySmall">of</Typography>
        <PaginationItem>
          {totalCount.isLoading ? <Loader inline size={21} /> : lastPage}
        </PaginationItem>
      </PaginationContainer>
      <Button
        disabled={totalCount.isLoading || currentPage >= lastPage}
        onClick={onNext}
        type="button"
      >
        <CaretRightIcon />
      </Button>
    </Container>
  );
};

export default Pagination;
