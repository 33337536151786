import styled from 'styled-components';
import { BodyStandard } from 'styleguide/src/components/Typography/Typography.styles';

export const DateContainer = styled.div`
  color: ${(props) => props.theme.colors.black};
  ${BodyStandard};
`;
export const HoursContainer = styled.div`
  color: #5d5d5d;
`;
