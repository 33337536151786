import type { WorkOrder } from 'common/types/WorkOrder';

import Typography from 'styleguide/src/components/Typography';

interface MarketCellProps {
  workOrder: WorkOrder;
}

const MarketCell = ({ workOrder }: MarketCellProps) => (
  <Typography variant="bodyStandard" tagName="div" color={'black'}>
    {`${workOrder.market?.cityName}, ${workOrder.market?.stateCode}`}
  </Typography>
);

export default MarketCell;
