import styled from 'styled-components';
import Button from 'styleguide/src/components/Button';
import { ButtonLabel } from 'styleguide/src/components/Button/Button.styles';
import Typography from 'styleguide/src/components/Typography';

export const Container = styled.section`
  margin: 1rem 0 1rem 20px;
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

export const StatusChipLists = styled.ul`
  white-space: nowrap;
  margin: 0;
  padding: 0;
`;

export const StatusChipList = styled.li`
  list-style: none;
  display: inline-block;
`;

export const StatusChipLabel = styled(Typography)`
  flex-shrink: 0;
`;

export const StatusChipContainer = styled(Button)<{ active: boolean }>`
  margin: 0 0 0 1rem;
  padding: 6px 12px;
  height: 32px;
  border: ${({ theme, active }) =>
    `1px solid ${active ? theme.colors.machineGreen600 : theme.colors.gray50}`};
  ${ButtonLabel} {
    display: flex;
    align-items: center;
  }
  border-radius: 8px;
`;

export const StatusChipIcon = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: 10px;
`;

export const ClearButton = styled(Button)`
  margin-left: 20px;
  text-decoration: underline;
`;
