import Typography from 'styleguide/src/components/Typography';
import { WorkOrderStatus } from 'common/types/WorkOrderStatus';
import { WORK_ORDER_STATUS_MAP } from 'utils/mappings/workOrderStatus';
import { StatusChipContainer, StatusChipIcon } from './index.styles';

type Props = {
  status: WorkOrderStatus;
  onClick: (status: WorkOrderStatus) => void;
  active: boolean;
};

const StatusChip = ({ active, status, onClick }: Props) => {
  const onChipClick = () => {
    onClick(status);
  };
  return (
    <StatusChipContainer
      variant="tertiary"
      active={active}
      onClick={onChipClick}
    >
      <StatusChipIcon color={WORK_ORDER_STATUS_MAP[status].color} />
      <Typography variant="bodyLarge">
        {WORK_ORDER_STATUS_MAP[status].label}
      </Typography>
    </StatusChipContainer>
  );
};

export default StatusChip;
