import { differenceInHours, format, formatDistanceToNow } from 'date-fns';

export const convertTimestampToDistanceWords = (time?: string) => {
  if (!time) {
    return 'unknown';
  }
  const date = new Date(time);
  const distanceInWords = formatDistanceToNow(date, {
    addSuffix: true,
  });
  const hoursDiff = differenceInHours(date, new Date());

  // If date more then 24 hours ago, then change format
  if (hoursDiff >= 24) {
    return format(date, 'MMMM dd, yyyy');
  }
  return distanceInWords.replace('about', '').replace('minutes', 'mins');
};
