import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/store';
import {
  setCurrentProjectAndJobsView as setCurrentProjectAndJobsViewAction,
  setCurrentProjectAndJobsViewCompletion,
  clearCurrentProjectAndJobsView,
  ProjectPrePostCompleteState,
} from 'common/store/slices/workOrderSlice';

// Types
import type { SortingRule, CellProps } from 'react-table';
import {
  WorkOrder,
  WorkOrderPreCompletionStatus,
  WorkOrderPostCompletionStatus,
  WorkOrderType,
  WORK_ORDER_PRE_COMPLETION_STATUSES,
  WORK_ORDER_POST_COMPLETION_STATUSES,
  WorkOrderFilters,
  WorkOrderSortOptions,
} from 'common/types/WorkOrder';
import type { ColumnType } from 'styleguide/src/components/Table';

// Components
import JobDetail from 'components/jobs/detail/JobDetail';
import Loader from 'styleguide/src/components/Loader';
import Table from 'styleguide/src/components/Table';
import Tabs from 'styleguide/src/components/Tabs';
import Typography from 'styleguide/src/components/Typography';
import ScheduledShiftCell from '../ScheduledShiftCell/ScheduledShiftCell';
import FormsCell from '../FormsCell/FormsCell';
import TitleCell from '../TitleCell/TitleCell';
import StatusCell from '../StatusCell/StatusCell';

// Styles
import {
  Wrapper,
  LoaderContainer,
  TableWrapper,
  Pagination,
} from '../workOrders.styles';
import WorkOrdersHeader from '../WorkOrdersHeader/WorkOrdersHeader';
import AssigneeCell from '../AssigneeCell/AssigneeCell';
import Warning from 'styleguide/src/components/Warning';
import MarketCell from '../MarketCell/MarketCell';
import { useGetWorkOrdersQuery } from 'services/endpoints/workOrder';
import CountCell from '../CountCell/CountCell';
import { useRouter } from 'next/router';
import useAccount from 'common/hooks/useAccount';
import { convertTimestampToDistanceWords } from 'utils/helpers/convertTimestampToDistanceWords';
import FilterByStatus from '../FilterByStatus';
import { sortingRulesToPaginationSort } from 'utils/pagination';

export type WorkOrderCompletionStatus =
  | WorkOrderPreCompletionStatus[]
  | WorkOrderPostCompletionStatus[];

type JobTableProps = {
  showPrePostCompletionFilters?: boolean;
  filters: Partial<WorkOrderFilters>;
  activeJobId?: number;
  enableHistoryState?: boolean;
  onSelectWorkOrder?: (workOrderId: number) => void;
  onBackToUserJob?: () => void;
};

const PAGE_SIZE = 10;

const WorkOrdersTable = ({
  showPrePostCompletionFilters,
  filters,
  activeJobId,
  enableHistoryState,
  onSelectWorkOrder,
  onBackToUserJob,
}: JobTableProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { isAuthenticated } = useAccount();
  const currentProjectAndJobsView = useAppSelector(
    (state) => state.workOrder.currentProjectAndJobsView,
  );
  const currentProjectAndJobsViewCompletion = useAppSelector(
    (state) => state.workOrder.currentProjectAndJobsViewCompletion,
  );

  const [filterByStatus, setFilterByStatus] =
    useState<WorkOrderCompletionStatus>([]);

  const initialFilters = useMemo(() => {
    return currentProjectAndJobsViewCompletion === 0
      ? [...WORK_ORDER_PRE_COMPLETION_STATUSES]
      : [...WORK_ORDER_POST_COMPLETION_STATUSES];
  }, [currentProjectAndJobsViewCompletion]);

  useEffect(() => {
    if (currentProjectAndJobsViewCompletion === 0) {
      setFilterByStatus([...WORK_ORDER_PRE_COMPLETION_STATUSES]);
    } else {
      setFilterByStatus([...WORK_ORDER_POST_COMPLETION_STATUSES]);
    }
  }, [currentProjectAndJobsViewCompletion]);

  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState('-updatedAt');

  const finalFilters = useMemo(() => {
    let filter: Partial<WorkOrderFilters> = { ...filters };
    if (showPrePostCompletionFilters) {
      filter = { type: currentProjectAndJobsView, ...filter };
    }

    filter = {
      ...filter,
      statuses: filterByStatus.length === 0 ? initialFilters : filterByStatus,
    };

    return filter;
  }, [
    filters,
    showPrePostCompletionFilters,
    filterByStatus,
    initialFilters,
    currentProjectAndJobsView,
  ]);

  const { data, isFetching, error } = useGetWorkOrdersQuery(
    {
      filters: finalFilters,
      pagination: {
        page: currentPage,
        limit: PAGE_SIZE,
        order: sort,
      },
    },
    {
      refetchOnMountOrArgChange: 60,
      skip: !isAuthenticated,
    },
  );

  const workOrders = useMemo(() => data?.results || [], [data]);

  useEffect(() => {
    if (!enableHistoryState) {
      dispatch(clearCurrentProjectAndJobsView());
    }
    return () => {
      if (!enableHistoryState) {
        dispatch(clearCurrentProjectAndJobsView());
      }
    };
  }, [dispatch, enableHistoryState]);

  const onRowSelect = useCallback(
    (rowNumber: number) => {
      const id = workOrders[Number(rowNumber)].id;
      if (onSelectWorkOrder) {
        onSelectWorkOrder(id);
      } else {
        router.push(`/jobs-and-projects/${id}`);
      }
    },
    [onSelectWorkOrder, router, workOrders],
  );

  const initialState = useMemo(() => {
    const columnOptions = {
      sortBy: [
        {
          id: 'updatedAt',
          desc: true,
        },
      ],
    };
    if (currentProjectAndJobsView === 'PROJECT') {
      return {
        ...columnOptions,
        hiddenColumns: [
          'updatedAt',
          'createdAt',
          'acceptedTimeSlotStart',
          'assigneeName',
          'formCount',
          'status',
        ],
      };
    } else if (currentProjectAndJobsView === 'JOB') {
      return {
        ...columnOptions,
        hiddenColumns: ['jobCount', 'createdAt'],
      };
    }
    return columnOptions;
  }, [currentProjectAndJobsView]);

  const columns = useMemo<ColumnType<WorkOrder & WorkOrderSortOptions>>(
    () => [
      {
        Header:
          showPrePostCompletionFilters &&
          currentProjectAndJobsView === 'PROJECT'
            ? 'Project Title'
            : 'Job Title',
        Cell: ({ row }: CellProps<WorkOrder>) => (
          <TitleCell {...row.original} />
        ),
        accessor: 'title',
      },
      {
        Header: 'Location',
        accessor: 'locationName',
        Cell: ({ row }: CellProps<WorkOrder>) => {
          const { street, building, postalCode } = row.original.location;
          return (
            <Typography variant="bodyStandard" tagName="div" color="black">
              {`${building} ${street}, ${postalCode}`}
            </Typography>
          );
        },
      },
      {
        Header: 'Market',
        Cell: ({ row }: CellProps<WorkOrder>) => (
          <MarketCell workOrder={row.original} />
        ),
        accessor: 'marketName',
      },
      {
        Header: 'Scheduled Shift',
        Cell: ({ row }: CellProps<WorkOrder>) => (
          <ScheduledShiftCell {...row.original} />
        ),
        accessor: 'acceptedTimeSlotStart',
      },
      {
        Header: 'Assignee',
        Cell: ({ row }: CellProps<WorkOrder>) => (
          <AssigneeCell {...row.original} />
        ),
        accessor: 'assigneeName',
      },
      {
        Header: 'Created At',
        Cell: ({ row }: CellProps<WorkOrder>) => (
          <Typography variant="bodyStandard" tagName="div" color={'black'}>
            {convertTimestampToDistanceWords(row.original.createdAt)}
          </Typography>
        ),
        accessor: 'createdAt',
      },
      {
        Header: 'Updated',
        Cell: ({ row }: CellProps<WorkOrder>) => (
          <Typography variant="bodyStandard" tagName="div" color="black">
            {convertTimestampToDistanceWords(row.original.updatedAt)}
          </Typography>
        ),
        accessor: 'updatedAt',
      },
      {
        Header: 'Forms',
        Cell: ({ row }: CellProps<WorkOrder>) => (
          <FormsCell {...row.original} />
        ),
        accessor: 'formCount',
      },
      {
        Header: 'Job Count',
        Cell: ({ cell }: CellProps<WorkOrder>) => {
          return <CountCell workOrder={cell.row.original} />;
        },
        accessor: 'jobCount',
      },
      {
        Header: 'Status',
        Cell: (prop: CellProps<WorkOrder>) => {
          return (
            <StatusCell
              onShowDetails={
                onSelectWorkOrder
                  ? (workOrder) => onSelectWorkOrder(workOrder.id)
                  : undefined
              }
              workOrder={prop.row.original}
            />
          );
        },
        accessor: 'status',
      },
    ],
    [
      showPrePostCompletionFilters,
      onSelectWorkOrder,
      currentProjectAndJobsView,
    ],
  );

  const workOrder = useMemo(() => {
    return workOrders.find((w) => w.id === activeJobId);
  }, [activeJobId, workOrders]);

  const onSelectFiltersByStatus = useCallback(
    (selectedFilters: WorkOrderCompletionStatus) => {
      setFilterByStatus(selectedFilters);
    },
    [],
  );

  const onSortChange = useCallback(
    (sort: SortingRule<WorkOrderSortOptions>[]) => {
      setSort(sortingRulesToPaginationSort(sort));
    },
    [],
  );

  const render = useCallback(() => {
    return (
      <Wrapper>
        {isFetching && (
          <LoaderContainer>
            <Loader inline />
          </LoaderContainer>
        )}
        <FilterByStatus
          onSelect={onSelectFiltersByStatus}
          filters={filterByStatus}
          filterLists={initialFilters}
        />
        <Table
          columns={columns as ColumnType<WorkOrder>}
          data={workOrders}
          rowHeight={88}
          onRowSelect={onRowSelect}
          initialState={initialState}
          onSortChange={onSortChange}
        />
        {data?.totalCount > PAGE_SIZE && (
          <Pagination
            onPageChange={(page) => setCurrentPage(page)}
            currentPage={currentPage}
            totalCount={{ value: data.totalCount, isLoading: false }}
            pageSize={PAGE_SIZE}
          />
        )}
      </Wrapper>
    );
  }, [
    data?.totalCount,
    onSortChange,
    columns,
    filterByStatus,
    initialFilters,
    initialState,
    currentPage,
    isFetching,
    onRowSelect,
    onSelectFiltersByStatus,
    workOrders,
  ]);

  const tabLists = useMemo(() => {
    if (showPrePostCompletionFilters) {
      return [
        {
          label: 'Pre-Completion',
          render: render(),
        },
        {
          label: 'Post-Completion',
          render: render(),
        },
      ];
    } else {
      return [
        {
          label: 'Active Jobs',
          render: render(),
        },
        {
          label: 'Job History',
          render: render(),
        },
      ];
    }
  }, [render, showPrePostCompletionFilters]);

  const setCurrentProjectAndJobsView = useCallback(
    (type: WorkOrderType) => {
      dispatch(setCurrentProjectAndJobsViewAction(type));
    },
    [dispatch],
  );

  const onChangeTab = (index: ProjectPrePostCompleteState) => {
    dispatch(setCurrentProjectAndJobsViewCompletion(index));
  };

  const onErrorResolve = () => {
    window.location.reload();
  };

  if (error) {
    if (showPrePostCompletionFilters)
      return <Warning inline full onResolve={onErrorResolve} />;
    return <Warning inline onResolve={onErrorResolve} />;
  }

  return (
    <>
      {showPrePostCompletionFilters && (
        <WorkOrdersHeader
          onTableChange={setCurrentProjectAndJobsView}
          tab={currentProjectAndJobsView}
        />
      )}
      <TableWrapper>
        {activeJobId ? (
          <JobDetail workOrder={workOrder} onBackToUserJob={onBackToUserJob} />
        ) : (
          <Tabs
            defaultTabIndex={currentProjectAndJobsViewCompletion}
            tabLists={tabLists}
            onChange={onChangeTab}
          />
        )}
      </TableWrapper>
    </>
  );
};

export default WorkOrdersTable;
