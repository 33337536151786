import type { WorkOrder } from 'common/types/WorkOrder';

import { CountCellBody } from './CountCell.styles';
import ContextButtons from '../ContextButtons/ContextButtons';

interface CountCellProps {
  workOrder: WorkOrder;
}

const StatusCell = ({ workOrder }: CountCellProps) => {
  return (
    <>
      <CountCellBody>{workOrder.subJobs?.length || 0}</CountCellBody>
      <ContextButtons workOrder={workOrder} />
    </>
  );
};

export default StatusCell;
